import { ProviderLayoutDefault } from '@/domains/practitioner/layouts';
import { RouteConfig } from 'vue-router';
import { makeRouteNames } from '@/router/helpers';

const routeNames = makeRouteNames({
  bookings: {
    my: {
      day: 'bookings.my.day',
      list: 'bookings.my.list',
    },
    view: 'bookings.view',
  },
});

const routes: Array<RouteConfig> = [
  {
    path: '/schedule',
    component: () => import('../views/MySchedule/MySchedulePage.vue'),
    meta: {
      layout: ProviderLayoutDefault,
    },
    children: [
      {
        path: '',
        name: routeNames.bookings.my.day,
        component: () => import('../views/MySchedule/MyDailySchedulePage.vue'),
      },
      {
        path: 'list',
        name: routeNames.bookings.my.list,
        component: () => import('../views/BookingListPage/BookingListPage.vue'),
      },
    ],
  },
  {
    path: '/bookings/:uuid',
    component: () => import('../views/ViewBookingPage/ViewBookingPage.vue'),
    name: routeNames.bookings.view,
    meta: {
      layout: ProviderLayoutDefault,
    },
  },
];

export const bookingRoutes = { routeNames, routes };
