/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Vuex, { Store } from 'vuex';

Vue.use(Vuex);

export const makeStore = (): Store<any> =>
  new Vuex.Store<any>({
    modules: {},
  });
