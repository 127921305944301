/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { APIRequestConfig } from '@/services';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const debug = {
  log(...data: any[]): void {
    console.log(...data);
  },

  coloured(data: any, colour: 'red' | 'orange' | 'yellow' | 'white' | 'blue' = 'orange'): void {
    console.log(`%c${data}`, `color: ${colour};`);
  },

  api(config: APIRequestConfig): void {
    console.log(
      `API Call: %c${config.name} %c${config.method?.toUpperCase()}: %c${config.url}`,
      'color: orange;',
      'color: white',
      'color: white'
    );
  },
};
