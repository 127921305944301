import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import {
  ComponentInternalInstance,
  Ref,
  UnwrapRef,
  getCurrentInstance,
  reactive,
  toRefs,
  watch,
} from '@/plugins/composition';
import { patientRouteNames, patientRoutes } from '@/domains/patient/router';
import { practitionerRouteNames, practitionerRoutes } from '@/domains/practitioner/router';
import { sharedRoutes } from '@/domains/shared/router/sharedRoutes';
import { storage } from '../main';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...practitionerRoutes,
  ...sharedRoutes,
  ...patientRoutes,
  // { path: '*', redirect: 'dashboard' },
];

export const routeNames = {
  practitioner: practitionerRouteNames,
  patient: patientRouteNames,
};

Vue.mixin({
  beforeCreate() {
    this.$routes = routeNames;
  },
});

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

async function checkAuth(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  await storage.auth.checkAuth();
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth != false);
  const authed = storage.auth.isAuthed;
  if (requiresAuth != false && !authed) {
    next({ name: 'login', query: { redirect: to.fullPath, client: to.query.client } });
  } else {
    next();
  }
}

router.beforeEach(async (to, from, next) => {
  await checkAuth(to, from, next);

  next();
});

const DEFAULT_TITLE = 'HPWHI';

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to?.meta?.title ? `HPWHI | ${to?.meta.title}` : DEFAULT_TITLE;
  });
});

export const redirectOnFail = async (callback: () => Promise<void>, name: string): Promise<void> => {
  try {
    await callback();
  } catch (e) {
    router.push({ name: name });
  }
};

export const useRouter = (): {
  router: VueRouter;
  route: Ref<UnwrapRef<Route>>;
} => {
  const instance = getCurrentInstance() as ComponentInternalInstance;

  const vm = instance.proxy;

  const state = reactive({
    route: vm.$route,
  });

  watch(
    () => vm.$route,
    (r) => {
      state.route = r;
    }
  );

  return {
    ...toRefs(state),
    router: vm.$router,
  };
};
