import { BaseModel } from './BaseModel';

export * from './Address';
export * from './User';
export * from './Client';
export * from './Patient';
export * from './Site';
export * from './Department';
export * from './Position';
export * from './Shift';
export * from './Booking';
export * from './Schedule';
export * from './Practitioner';
export * from './BookingReason';
export * from './Conversation';
export * from './Attachment';
export * from './Interaction';
export * from './Survey';
export * from './Question';
export * from './Assessment';
export * from './GroupInteraction';
export * from './ResourceTopic';
export * from './Resource';

export const isEqual = (model: BaseModel | null, compare: BaseModel | null): boolean => {
  return model?.uuid == compare?.uuid;
};
