import { BaseModel, ModelRelations } from '../BaseModel';
import { Builder } from '../Builder';
import { Patient, PatientData, Survey, SurveyData } from '..';
import { Question } from '../Question';

export type AnswerData = {
  uuid: string;
  question: string;
  value: string | number | null;
};

export type AssessmentData = {
  uuid: string;
  patient: PatientData;
  answers: AnswerData[];
  survey: SurveyData;
};

export class Assessment extends BaseModel<AssessmentData> {
  public patient!: Patient;
  public survey!: Survey;

  questions(): Builder<Question> {
    return this.hasLazy(Question, { nested: false });
  }

  relations(): ModelRelations<Assessment> {
    return {
      patient: this.hasOne(Patient, { nullable: false }),
      survey: this.hasOne(Survey, { nullable: false }),
    };
  }
}
