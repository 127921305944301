






// import PatientSelectInput from '@/components/inputs/PatientSelectInput.vue';
import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  // components: { PatientSelectInput },
  name: 'HeaderSearch',
});
