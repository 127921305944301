import { Address, AddressData } from './address';
import { User } from '../User';

const data: AddressData = {
  uuid: '',
  address_1: '',
  address_2: '',
  address_3: '',
  postcode: '',
  suburb: '',
  state: '',
  country: '',
  traditionalPlaceName: '',
  lng: '',
  lat: '',
};

export const addressFactory = (parent: User): Address => {
  const address = new Address(data);
  address.parent = () => parent;
  return address;
};
