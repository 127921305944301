









import { computed, defineComponent, ref } from '@/plugins/composition';
import { formatDate } from '@/utils';

export default defineComponent({
  name: 'BaseClock',
  setup() {
    const time = ref(new Date());

    const timeString = computed(() => formatDate(time.value.toISOString(), 'hh:mm'));
    const dateString = computed(() => formatDate(time.value.toISOString()));

    setInterval(() => (time.value = new Date()), 1000);

    return {
      timeString,
      dateString,
    };
  },
});
