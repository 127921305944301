import { ProviderLayoutDefault } from '../../../layouts';
import { RouteConfig } from '@/types/router';
import { makeRouteNames } from '@/router/helpers';

const routeNames = makeRouteNames({
  resources: {
    topics: {
      all: 'resources.topics.all',
      view: 'resources.topics.view',
    },
  },
});

const routes: Array<RouteConfig> = [
  {
    path: '/resources',
    component: () => import('../views/ResourcesModulePage.vue'),
    meta: {
      layout: ProviderLayoutDefault,
    },
    children: [
      {
        path: 'topics',
        name: routeNames.resources.topics.all,
        component: () => import('../views/AllTopicsPage.vue'),
        meta: {
          title: 'Resource Topics',
        },
      },
      {
        path: 'topics/:uuid',
        name: routeNames.resources.topics.view,
        component: () => import('../views/ViewTopicPage.vue'),
        props: true,
        meta: {
          title: 'Resource Topics',
        },
      },
    ],
  },
];

export const resourceRoutes = { routeNames, routes };
