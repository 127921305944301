import baseAxios from 'axios';
import { APIRequestConfig } from '@/services';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { User } from '@/models/User';
import { debug } from '@/utils/console';
import { router } from '@/router';
import { storage } from '@/main';

const config: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    // 'Prevent-Lazy-Loading': true,
  },
};

const axios: AxiosInstance = baseAxios.create(config);

axios.interceptors.request.use(
  function (config: APIRequestConfig) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    storage.status.setErrorMessage(error.response.data.message);
    if (error.response.config.name) {
      debug.api(error.response.config);
    }
    if (error.response && [401].includes(error.response.status)) {
      console.log(error.response);
      if (storage.auth.state.user) {
        await User.logout();
        router.push({ name: 'login' });
      } else {
        await User.logout();
        router.push({ name: 'login' });
      }
    }
    return Promise.reject(error);
  }
);

export { axios };
