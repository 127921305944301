/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIResponse } from './apiService.types';
import { AxiosRequestConfig } from 'axios';
import { axios } from '@/plugins/axios';

export interface APIRequestConfig extends AxiosRequestConfig {
  scoped?: boolean;
  name?: string;
}

export class APIService {
  constructor(public defaultBaseUrl: string | undefined = axios.defaults.baseURL) {}

  baseUrl(options: APIRequestConfig = { scoped: true }): string {
    if (options.scoped) {
      //
    }
    const url = this.defaultBaseUrl;

    return url ?? '';
  }

  config(options?: APIRequestConfig): APIRequestConfig {
    return {
      baseURL: this.baseUrl(options),
      name: options?.name,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get<T = any>(url: string, options?: APIRequestConfig): APIResponse<T> {
    const response = await axios.get(url, this.config(options));
    return response.data;
  }

  async delete<T = any>(url: string, options?: APIRequestConfig): APIResponse<T> {
    const response = await axios.delete(url, this.config(options));
    return response.data;
  }

  async post<T = any>(url: string, data: any = null, options?: APIRequestConfig): APIResponse<T> {
    const response = await axios.post(url, data, this.config(options));
    return response.data;
  }

  async put(url: string, data: any = null, options?: APIRequestConfig): APIResponse {
    const response = await axios.put(url, data, this.config(options));
    return response.data;
  }
}
