












import BaseRouter from '@/components/BaseRouter.vue';
import ConfirmDialog from './domains/shared/components/confirm-dialog/ConfirmDialog.vue';
import MessageBar from './components/MessageBar.vue';
import Vue, { VueConstructor } from 'vue';
import { Route, RouteRecord } from 'vue-router';
import { computed, defineComponent } from '@/plugins/composition';
import { useRouter } from './router';

export default defineComponent({
  components: {
    BaseRouter,
    MessageBar,
    ConfirmDialog,
  },
  setup() {
    const { route, router } = useRouter();

    const currentLayout = computed(() => {
      // This route has a layout, so return it
      if (routeHasLayout(route.value as Route)) {
        return route.value.meta?.layout;
      }

      // Find the matched routes so we can get the parent route
      const matched = route.value.matched.find((r) => r.name == route.value.name) as RouteRecord;

      // We found the matched route
      if (matched) {
        return getParentLayout(matched);
      }

      // TODO: account for 'default' layout if layout isn't set
      return route.value.meta?.layout;
    });

    const getParentLayout = (route: RouteRecord): null | VueConstructor<Vue> => {
      if (route.parent?.meta?.layout) {
        return route.parent?.meta?.layout;
      }

      if (route.parent?.parent) {
        return getParentLayout(route.parent);
      }

      return null;
    };

    const routeHasLayout = (route: Route): null | VueConstructor<Vue> => {
      return route.meta?.layout;
    };

    return {
      currentLayout,
    };
  },
});
