import { NavigationGuardNext, Route } from 'vue-router';
import { RouteConfig } from '@/types/router';
import { makeRouteNames } from '@/router/helpers';
import { storage } from '@/main';

const routeNames = makeRouteNames({
  patients: {
    all: 'patients.all',
    view: 'patients.view.profile',
  },
});

const routes: Array<RouteConfig> = [
  {
    path: '',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue'),
    meta: {
      layout: () => import('@/domains/shared/layouts/shared-layout-main/SharedLayoutMain.vue'),
      requiresAuth: false,
    },
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
      const authed = storage.auth.isAuthed;
      if (authed) {
        const user = storage.auth.user;
        if (user.data.type === 'practitioner') {
          next('/dashboard');
        } else {
          next('/user/resources');
        }
      } else {
        next();
      }
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterPage.vue'),
    meta: {
      layout: () => import('@/domains/shared/layouts/shared-layout-main/SharedLayoutMain.vue'),
      requiresAuth: false,
    },
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
      const authed = storage.auth.isAuthed;
      if (authed) {
        const user = storage.auth.user;
        if (user.data.type === 'practitioner') {
          next('/dashboard');
        } else {
          next('/user/resources');
        }
      } else {
        next();
      }
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../views/ForgotPasswordPage.vue'),
    meta: {
      layout: () => import('@/domains/shared/layouts/shared-layout-main/SharedLayoutMain.vue'),
      requiresAuth: false,
    },
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
      const authed = storage.auth.isAuthed;
      if (authed) {
        const user = storage.auth.user;
        if (user.data.type === 'practitioner') {
          next('/dashboard');
        } else {
          next('/user/resources');
        }
      } else {
        next();
      }
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('../views/WelcomePage.vue'),
    meta: {
      layout: () => import('@/domains/shared/layouts/shared-layout-main/SharedLayoutMain.vue'),
      requiresAuth: false,
    },
    props: (route) => ({ email: route.query.email, token: route.query.token }),
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
      const authed = storage.auth.isAuthed;
      if (authed) {
        const user = storage.auth.user;
        if (user.data.type === 'practitioner') {
          next('/dashboard');
        } else {
          next('/user/resources');
        }
      } else {
        next();
      }
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPasswordPage.vue'),
    meta: {
      layout: () => import('@/domains/shared/layouts/shared-layout-main/SharedLayoutMain.vue'),
      requiresAuth: false,
    },
    props: (route) => ({ email: route.query.email, token: route.query.token }),
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
      const authed = storage.auth.isAuthed;
      if (authed) {
        const user = storage.auth.user;
        if (user.data.type === 'practitioner') {
          next('/dashboard');
        } else {
          next('/user/resources');
        }
      } else {
        next();
      }
    },
  },
];

export const authRoutes = { routeNames, routes };
