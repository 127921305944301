import { DeepReadonly, reactive, readonly } from '@/plugins/composition';
import { IServiceProvider } from '@/services';
import { Patient, PatientData, Practitioner, PractitionerData } from '@/models';
import { User } from '@/models/User';

export interface IAuthState {
  authenticated: boolean;
  user: User | null;
  provider: string;
  location: string;
}

const initialState = {
  user: null,
  authenticated: false,
  provider: '',
  location: '',
};

export class AuthStorage {
  constructor(public $services: IServiceProvider, public store: IAuthState = reactive({ ...initialState })) {}

  getLocalAuth(): IAuthState | null {
    const storedAuth = window.localStorage.getItem('auth');
    return storedAuth ? JSON.parse(storedAuth) : null;
  }

  setLocalAuth(state: IAuthState): void {
    window.localStorage.setItem('auth', JSON.stringify(state));
  }

  clearLocalAuth(): void {
    window.localStorage.clear();
  }

  get state(): DeepReadonly<IAuthState> {
    return readonly(this.store);
  }

  get isAuthed(): boolean {
    return this.state.authenticated;
  }

  get provider(): string | null {
    return this.state.provider;
  }

  get asPractitioner(): Practitioner {
    return new Practitioner({ uuid: this.state.user?.uuid } as PractitionerData);
  }

  get asPatient(): Patient {
    return new Patient({ uuid: this.state.user?.uuid } as PatientData);
  }

  get auth(): DeepReadonly<IAuthState> {
    return this.state;
  }

  get user(): User {
    return this.state.user as User;
  }

  login(user: User): boolean {
    this.store.user = user;
    this.store.authenticated = true;
    this.setLocalAuth(this.store);
    return true;
  }

  async checkAuth(): Promise<User | null> {
    let user: User | null = null;
    const storedUser = this.getLocalAuth();
    if (storedUser) {
      try {
        user = await User.getCurrentUser();
        this.login(user);
      } catch (e) {
        this.clearLocalAuth();
      }
    }
    return user;
  }

  setProvider(provider: string): void {
    this.store.provider = provider;
  }

  setLocation(location: string): void {
    this.store.location = location;
  }

  logout(): void {
    this.clearLocalAuth();
    Object.assign(this.state, initialState);
  }

  async practitioner(): Promise<Practitioner | null> {
    if (!this.state.user) {
      return null;
    }

    return Practitioner.find(this.state.user.uuid);
  }
}
