import { Address, AddressData } from '../Address';
import { AuthService } from '@/services';
import { AuthStorage } from '@/store/auth';
import { BaseModel, ModelRelations } from '../BaseModel';
import { Practitioner } from '..';
import { addressFactory } from '../Address/addressFactory';
import { axios } from '@/plugins/axios';
import { snakeKeys } from '@/utils';

export type UserData = {
  readonly uuid: string;
  readonly email: string;
  readonly name?: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly lastLogin?: string;
  readonly access?: {
    canLogin: boolean;
    state: string;
  };
  readonly address: AddressData;
  readonly mobile?: string;
  readonly phone?: string;
  readonly role: string;
  readonly gender: string;
  readonly dob: string;
  readonly age: string | number;
  readonly avatar: string;
  type: string;
};

export interface HasAvatar {
  avatar: string;
  uuid: string;
  uploadAvatar: (formData: FormData) => Promise<void>;
  initials: string;
}

export type LoginCredentials = {
  email: string;
  password: string;
};

export type ResetPasswordData = LoginCredentials & { token: string };

export class User<M extends UserData = UserData> extends BaseModel<M> implements HasAvatar {
  public address!: Address | null;
  public priamryPractitioner!: Practitioner | null;

  constructor(data: M = {} as M) {
    super(data);
    if (!this.address) {
      this.address = addressFactory(this);
    }
  }

  static get $service(): AuthService {
    return BaseModel.serviceProvider.auth;
  }

  static get $storage(): AuthStorage {
    return BaseModel.storageProvider.auth;
  }

  static async login(credentials: LoginCredentials): Promise<User> {
    const response = await User.$service.login(credentials);
    return response;
  }

  static async logout(): Promise<void> {
    const response = await User.$service.logout();
    User.$storage.logout();
    return response;
  }

  static async resetPassword(data: ResetPasswordData): Promise<User> {
    const response = await axios.post('/reset-password', snakeKeys(data));

    return new User(response.data);
  }

  static async getCurrentUser(): Promise<User> {
    const response = await BaseModel.$api.get('/user', {
      name: 'Get Current User',
    });
    return new User(response);
  }

  static async forgotPassword(email: string): Promise<unknown> {
    return await BaseModel.$api.post('/forgot-password', { email });
  }

  get uuid(): string {
    return this.data.uuid;
  }

  get name(): string {
    return `${this.data?.firstName} ${this.data?.lastName}`;
  }

  get avatar(): string {
    return this.data.avatar;
  }

  get isAdmin(): boolean {
    return ['admin', 'super-admin'].includes(this.data.role);
  }

  get initials(): string {
    return this.data.firstName[0] + this.data.lastName[0];
  }

  async uploadAvatar(formData: FormData): Promise<void> {
    formData.append('_method', 'PUT');
    const response = await axios.post(`${this.slug()}/${this.uuid}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response) {
      this.refresh();
    }
  }

  relations(): ModelRelations<User> {
    return {
      address: this.hasOne(Address),
    };
  }
}
function useApi(): { post: any } {
  throw new Error('Function not implemented.');
}
