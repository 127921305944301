import { Patient } from '@/models';
import { services } from '@/main';

export type Metric = {
  patient: Patient;
  question: string;
  results: { date: string; value: number }[];
  first: { date: string; value: number };
  last: { date: string; value: number; change: number };
};

export const getMetrics = async <Q extends string[]>(
  patient: Patient,
  survey: string,
  questions: Q
): Promise<Metric[]> => {
  const { api } = services;

  const query = `patients/${patient.uuid}/metrics?survey=${survey}&questions=${questions.join()}`;

  return api.get(query);
};
