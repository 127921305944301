import { BaseModel } from '../BaseModel';
import { ResourceTopicData } from '../ResourceTopic';
import { axios } from '@/plugins/axios';
import { snakeKeys } from '@/utils';

export type ResourceData = {
  uuid: string;
  name: string;
  type: 'video' | 'file';
  url: string;
  filePath: string | null;
  videoId: string;
  topic: ResourceTopicData;
};

type ViewData = {
  firstName: string;
  lastName: string;
  email: string;
  client: string;
};

export class Resource extends BaseModel<ResourceData> {
  async view(data: ViewData): Promise<boolean> {
    const response = await axios.post(`/resources/${this.data.uuid}/view`, snakeKeys(data));

    return !!response;
  }
}
