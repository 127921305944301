import mime from 'mime-types';
import { BaseModel } from '../BaseModel';
import { Patient } from '..';
import { axios } from '@/plugins/axios';

export type AttachmentData = {
  id: string;
  uuid: string;
  filename: string;
  type: string;
  path: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  exetension: string;
};

export class Attachment extends BaseModel<AttachmentData> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static upload = async (formData: FormData, attachable: Patient): Promise<any> => {
    const response = await axios.post(`${attachable.slug()}/${attachable.uuid}/attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.map((data: AttachmentData) => {
      return new Attachment(data);
    });
  };

  async download(): Promise<void> {
    const file = await axios.get(`/attachments/${this.uuid}`, { responseType: 'blob' });

    const blob = new Blob([file.data], { type: mime.lookup(this.data.path) as string });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = this.data.filename;
    link.click();
    URL.revokeObjectURL(link.href);
  }
}
