import { Booking, Conversation, Interaction, Patient, Schedule, Site, SiteData } from '..';
import { Builder } from '../Builder';
import { ModelRelations } from '../BaseModel';
import { User, UserData } from '../User';

export interface PractitionerData extends UserData {
  lastSeen: string | null;
  sites: SiteData[];
  primary: boolean;
  primarySites: string[];
}

export class Practitioner extends User<PractitionerData> {
  sites!: Site[];

  patients(): Builder<Patient> {
    return this.hasLazy(Patient);
  }

  bookings(): Builder<Booking> {
    return this.hasLazy(Booking);
  }

  schedules(): Builder<Schedule> {
    return this.hasLazy(Schedule);
  }

  conversations(): Builder<Conversation> {
    return this.hasLazy(Conversation);
  }

  smsConverations(): Builder<Conversation> {
    return this.hasLazy(Conversation, { nested: true, key: 'sms' });
  }

  emailConversations(): Builder<Conversation> {
    return this.hasLazy(Conversation, { nested: true, key: 'emails' });
  }

  interactions(): Builder<Interaction> {
    return this.hasLazy(Interaction);
  }

  relations(): ModelRelations<Practitioner> {
    return {
      sites: this.hasMany(Site),
    };
  }
}
