
















import Component from 'vue-class-component';
import Vue from 'vue';
import { cancel, cancelText, confirm, confirmText, confirmType, confirmed, message, pending } from './confirm';

@Component({
  name: 'ConfirmDialog',
  setup() {
    return {
      confirmed,
      pending,
      cancel,
      confirm,
      message,
      confirmText,
      cancelText,
      confirmType,
    };
  },
})
export default class extends Vue {}
