import { BaseModel } from '../BaseModel';
import { ClientData, SiteData } from '..';

export type GroupInteractiondData = {
  uuid: string;
  group: SiteData | ClientData;
  sentAt: string;
  sendAt: string;
  status: keyof typeof statusColours;
  content: string;
  excerpt: string;
  subject: string;
};

const statusColours = {
  scheduled: 'info',
  queued: 'warning ',
  sent: 'success',
  delivered: 'success',
  received: 'success',
};

export class GroupInteraction extends BaseModel<GroupInteractiondData> {
  getStatusColour(): string {
    return statusColours[this.data.status];
  }
}
