import { ProviderLayoutDefault } from '../../../layouts';
import { Route } from 'vue-router';
import { RouteConfig } from '@/types/router';
import { makeRouteNames } from '@/router/helpers';

const routeNames = makeRouteNames({
  patients: {
    all: 'patients.all',
    view: {
      profile: 'patients.view.profile',
      profileSms: 'patients.view.profile.sms',
      bookings: 'patients.view.bookings',
      attachments: 'patients.view.attachments',
      assessments: 'patients.view.assessments',
    },
    create: 'patients.create',
  },
});

const routes: Array<RouteConfig> = [
  {
    path: '/patients',
    component: () => import('../views/PatientsModulePage.vue'),
    meta: {
      layout: ProviderLayoutDefault,
    },
    children: [
      {
        path: '',
        name: routeNames.patients.all,
        component: () => import('../views/PatientsIndexPage.vue'),
        meta: {
          title: 'Patients',
        },
      },
      {
        path: 'create',
        name: routeNames.patients.create,
        component: () => import('../views/PatientsCreatePage.vue'),
        meta: {
          title: 'Create',
        },
      },
      {
        path: ':uuid',
        component: () => import('../views/PatientsViewPage/PatientsViewPage.vue'),
        props: (route: Route) => ({
          sms: route.name === routeNames.patients.view.profileSms,
        }),
        children: [
          {
            path: '',
            name: routeNames.patients.view.profile,
            component: () => import('../views/PatientsViewPage/PatientProfile.vue'),
            meta: {
              title: 'Patient',
            },
          },
          {
            path: 'sms',
            name: routeNames.patients.view.profileSms,
            component: () => import('../views/PatientsViewPage/PatientProfile.vue'),
            meta: {
              title: 'Patient',
            },
            props: {
              sms: true,
            },
          },
          {
            path: 'bookings',
            name: routeNames.patients.view.bookings,
            component: () => import('../views/PatientsViewPage/PatientBookingsPage.vue'),
            meta: {
              title: 'Bookings',
            },
          },
          {
            path: 'attachments',
            name: routeNames.patients.view.attachments,
            component: () => import('../views/PatientsViewPage/PatientAttachmentsPage.vue'),
            meta: {
              title: 'Bookings',
            },
          },
          {
            path: 'assessments',
            name: routeNames.patients.view.assessments,
            component: () => import('../views/PatientsViewPage/PatientAssessmentsPage.vue'),
            meta: {
              title: 'Bookings',
            },
          },
        ],
      },
    ],
  },
];

export const patientRoutes = { routeNames, routes };
