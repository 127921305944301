






import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  name: 'BaseRouter',
})
export default class extends Vue {}
