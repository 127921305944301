import { AppendedAttributes, BaseModel, ModelRelations } from '../BaseModel';
import { Booking, Client, GroupInteraction, Practitioner, PractitionerData } from '..';
import { Builder } from '../Builder';
import { Department } from '../Department';
import { Patient } from '../Patient';

export type SiteData = {
  uuid: string;
  name: string;
  supervisors: Patient[];
  practitioners: Practitioner[];
};

export class Site extends BaseModel<SiteData> {
  primaryPractitioner?: Practitioner | null;
  loadedPractitioners: Practitioner[] | null = null;
  loadedSupervisors: Patient[] | null = null;
  eagerSupervisors!: Patient[] | null;
  eagerPractitioners!: Practitioner[] | null;
  client?: Client | null;

  get name(): string {
    return this.data.name;
  }

  bookings(): Builder<Booking> {
    return this.hasLazy(Booking, { nested: false });
  }

  groupInteractions(): Builder<GroupInteraction> {
    return this.hasLazy(GroupInteraction, { nested: false });
  }

  departments(): Builder<Department> {
    return this.hasLazy(Department, { nested: false });
  }

  patients(): Builder<Patient> {
    return this.hasLazy(Patient, { nested: false });
  }

  practitioners(): Builder<Practitioner> {
    return this.hasLazy(Practitioner, { nested: false });
  }

  supervisors(): Builder<Patient> {
    return this.patients().where('is_supervisor', true);
  }

  appends(): AppendedAttributes<Site> {
    return {
      primaryPractitioner: (data?: unknown) => (data ? new Practitioner(data as PractitionerData) : null),
    };
  }

  async loadSupervisors(): Promise<void> {
    if (!this.loadedSupervisors) {
      this.loadedSupervisors = await this.supervisors().get();
    }
  }

  async loadPractitioners(): Promise<void> {
    if (!this.loadedPractitioners) {
      this.loadedPractitioners = await this.practitioners().get();
    }
  }

  relations(): ModelRelations<Site> {
    return {
      client: this.hasOne(Client),
      eagerSupervisors: this.hasMany(Patient, { key: 'supervisors', loadRelations: false }),
      eagerPractitioners: this.hasMany(Practitioner, { key: 'practitioners', loadRelations: false }),
    };
  }
}
