






















import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class HeaderQuickNav extends Vue {
  apps = [
    {
      icon: 'mdi-email-outline',
      text: 'Email',
      key: 'menu.email',
      subtitle: 'Inbox',
      subtitleKey: 'email.inbox',
      link: '/apps/email/inbox',
    },
    {
      icon: 'mdi-format-list-checkbox',
      title: 'Tasks',
      key: 'menu.todo',
      subtitle: 'TODO',
      link: '/apps/todo',
    },
    {
      icon: 'mdi-message-outline',
      title: 'Chat',
      key: 'menu.chat',
      subtitle: '#general',
      link: '/apps/chat/channel/general',
    },
    {
      icon: 'mdi-view-column-outline',
      title: 'Board',
      key: 'menu.board',
      subtitle: 'Kanban',
      link: '/apps/board',
    },
  ];
}
