import hexRgb from 'hex-rgb';
import vuetify from '@/plugins/vuetify';
import { BaseModel, ModelRelations } from '../BaseModel';
import { BookingInterface, Practitioner, PractitionerData, Site } from '..';
import { formatDate, formatDateTime } from '@/utils';
import { formatISO } from 'date-fns';

export type ScheduleData = {
  uuid: string;
  startTime: string | number;
  endTime: string | number;
  practitioner: PractitionerData;
  createdAt: string;
  updatedAt: string;
};

export class Schedule extends BaseModel<ScheduleData> implements BookingInterface {
  public practitioner!: Practitioner;
  public site!: Site | null;
  public timed = false;

  get createdAt(): string {
    return formatDateTime(this.data.createdAt) as string;
  }

  get updatedAt(): string {
    return formatDateTime(this.data.updatedAt) as string;
  }

  colour(isDragged = false): string {
    const primary = vuetify.userPreset.theme?.themes?.light?.info as string;
    const colour = hexRgb(primary, { format: 'css', alpha: isDragged ? 0.7 : 1 });
    return colour;
  }

  get calendarLabel(): string {
    return this.site?.data.name ?? '';
  }

  get startTime(): string | null | number {
    return formatDate(this.data.startTime as string, 'yyyy-MM-dd HH:mm');
  }

  set startTime(value: string | null | number) {
    this.data.startTime = formatISO(new Date(value as string));
    this.tempData.startTime = formatISO(new Date(value as string));
  }

  get endTime(): string | null | number {
    return formatDate(this.data.endTime as string, 'yyyy-MM-dd HH:mm');
  }

  set endTime(value: string | null | number) {
    this.data.endTime = formatISO(new Date(value as string));
    this.tempData.endTime = formatISO(new Date(value as string));
  }

  get calStartTime(): string | null | number {
    return formatDate(this.data.startTime as string, 'yyyy-MM-dd');
  }

  get calEndTime(): string | null | number {
    return null;
  }

  relations(): ModelRelations<Schedule> {
    return {
      practitioner: this.hasOne(Practitioner, { nullable: false }),
      site: this.hasOne(Site, { nullable: false }),
    };
  }
}
