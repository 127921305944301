import { APIService } from '../api';
import { LoginCredentials } from './authService.types';
import { User } from '@/models/User';

export class AuthService {
  constructor(private api: APIService) {}

  async getCsrfCookie(): Promise<void> {
    await this.api.get('/sanctum/csrf-cookie', {
      name: 'CSRF Token',
    });
  }

  async login(credentials: LoginCredentials): Promise<User> {
    await this.getCsrfCookie();
    const response = await this.api.post('/login', credentials, {
      name: 'User Login',
    });
    return new User(response);
  }

  async logout(): Promise<void> {
    const response = await this.api.post('/logout', null, { scoped: false });
    console.clear();
    return response;
  }
}
