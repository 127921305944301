










































import BaseClock from '@/components/BaseClock.vue';
import HeaderNotifications from './components/HeaderNotifications.vue';
import HeaderQuickNav from './components/HeaderQuickNav.vue';
import HeaderSearch from './components/HeaderSearch.vue';
import HeaderUserProfile from './components/HeaderUserProfile.vue';
import MainMenu from './components/MainMenu.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'SharedLayoutMain',
  components: {
    HeaderSearch,
    HeaderNotifications,
    MainMenu,
    HeaderQuickNav,
    HeaderUserProfile,
    BaseClock,
  },
})
export default class ProviderLayoutDefault extends Vue {
  leftDrawer = true;
}
