import { BaseModel, ModelRelations } from '../BaseModel';
import { Builder } from '../Builder';
import { Interaction, InteractiondData, Patient, PatientData, PractitionerData } from '..';

export type ConversationData = {
  uuid: string;
  patient: PatientData;
  practitioner?: PractitionerData;
  latestInteractionTime: string;
  latestInteraction: InteractiondData;
};

export class Conversation extends BaseModel<ConversationData> {
  public patient!: Patient | null;

  interactions(): Builder<Interaction> {
    return this.hasLazy(Interaction, { nested: false });
  }

  async markAlAsInteractionsRead(): Promise<void> {
    await BaseModel.$api.post(this.directSlug() + 'mark-all-as-read');
  }

  relations(): ModelRelations<Conversation> {
    return {
      patient: this.hasOne(Patient),
    };
  }
}
