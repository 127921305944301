










































import BaseClock from '@/components/BaseClock.vue';
import HeaderNotifications from './components/HeaderNotifications.vue';
import HeaderQuickNav from './components/HeaderQuickNav.vue';
import HeaderSearch from './components/HeaderSearch.vue';
import HeaderUserProfile from './components/HeaderUserProfile.vue';
import MainMenu from './components/MainMenu.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { storage } from '@/main';

export default defineComponent({
  name: 'SharedLayoutMain',
  components: {
    HeaderSearch,
    HeaderNotifications,
    MainMenu,
    HeaderQuickNav,
    HeaderUserProfile,
    BaseClock,
  },
  setup() {
    const showDrawer = computed(() => {
      return storage.auth.isAuthed;
    });

    return {
      showDrawer,
      leftDrawer: ref(true),
    };
  },
});
