import { APIService } from '@/services';
import { BaseModel, BaseModelData } from './BaseModel';
import { Query } from './Query';
import { StatusStorage } from '@/store/status';
import { snakeKeys } from '@/utils/case';
import { storage } from '@/main';

interface BaseData extends Partial<BaseModelData> {
  uuid: string;
}

type CreateData = Omit<BaseData, 'uuid'>;

export interface PaginatedResponse<T> {
  data: T;
  meta: {
    total: number;
  };
}

export type Appends<M extends BaseModel> = (keyof M & string) | (keyof M['data'] & string);

export type QueryCallback<B> = (query: B) => B;

export class Builder<M extends BaseModel, D = BaseModelData> {
  selected?: keyof D;
  pageNumber?: number;

  constructor(
    public model: M,
    public modelConstructor: new (data: D) => M,
    public $api: APIService,
    public status: StatusStorage = storage.status,
    public query = new Query()
  ) {
    this.model = model;
    this.modelConstructor = modelConstructor;
  }

  /**
   * Query Functions
   *
   * These functions will modify the query class in prepared for the query execution
   */

  page(page: number): this {
    this.query.page(page);
    return this;
  }

  select(col: keyof D): this {
    this.selected = col;
    return this;
  }

  when(value: boolean, callback: QueryCallback<this>): this {
    if (value) {
      callback(this);
    }

    return this;
  }

  where<T>(property: string, value: T, nullable = false): this {
    if (!nullable && !value) {
      return this;
    }

    this.query.where(property, value);

    return this;
  }

  sortBy<T>(property: T, desc = false): this {
    this.query.sortBy(property, desc);
    return this;
  }

  with(...include: string[]): this {
    this.query.with(...include);

    return this;
  }

  append(...append: Appends<M>[]): this {
    this.query.append(...append);

    return this;
  }

  /**
   * Utility Functions
   *
   * These functions are used by the builder class internally
   */
  mapToModels(models: D[]): M[] {
    if (!models || models.length == 0) {
      return [];
    }

    return models.map((data: D) => {
      const model = new this.modelConstructor(data);
      const parent = this.model.parent();
      if (parent) {
        model.setParent(parent);
      }
      return model;
    });
  }

  /**
   * Final Methods
   *
   * These methods will finalise the query and execute it.
   */

  async find(uuid: string): Promise<M> {
    const slug = this.model.slug();

    const query = `/${slug}/${uuid}/${this.query.toString()}`;
    const response = await this.$api.get(query);

    return new this.modelConstructor(response);
  }

  async paginate(page?: number, perPage?: number): Promise<PaginatedResponse<M[]>> {
    if (page) {
      this.query.page(page);
    }

    if (perPage) {
      this.query.perPage(perPage);
    }

    const response = await this.getRaw<PaginatedResponse<D[]>>();

    return {
      data: this.mapToModels(response.data),
      meta: response.meta,
    };
  }

  async getAll(): Promise<M[]> {
    this.query.perPage(-1);
    this.query.page(1);

    const response = await this.getRaw<D[]>();

    return this.mapToModels(response);
  }

  async get(): Promise<M[]> {
    const response = await this.paginate();

    return response.data;
  }

  async directUpdate(data: BaseData, config = { message: true }): Promise<M> {
    const query = this.model.directSlug();

    const response = await this.$api.put(query, snakeKeys<BaseData>(data));

    if (config.message) {
      this.status.setMessage(this.model.messages.updated);
    }

    return new this.modelConstructor(response);
  }

  async update(data: BaseData, config = { message: true }): Promise<M> {
    const query = this.model.resourceSlug();

    const response = await this.$api.put(query, snakeKeys<BaseData>(data));

    if (config.message) {
      this.status.setMessage(this.model.messages.updated);
    }

    return new this.modelConstructor(response);
  }

  async directDelete(): Promise<M> {
    const query = this.model.directSlug();

    const response = await this.$api.delete(`/${query}`);

    this.status.setMessage(this.model.messages.updated);

    return new this.modelConstructor(response);
  }

  async delete(): Promise<M> {
    const query = this.model.resourceSlug();

    const response = await this.$api.delete(`/${query}`);

    this.status.setMessage(this.model.messages.updated);

    return new this.modelConstructor(response);
  }

  async create(data: CreateData): Promise<M> {
    const query = this.model.resourceSlug();
    const response = await this.$api.post(query, snakeKeys<CreateData>(data));

    this.status.setMessage(this.model.messages.created);

    return new this.modelConstructor(response);
  }

  async getRaw<T = D[] | PaginatedResponse<D[]>>(): Promise<T> {
    const slug = this.model.resourceSlug();

    const query = `/${slug}${this.query.toString()}`;
    const response = await this.$api.get<T>(query);

    return response;
  }
}
