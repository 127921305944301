import { BaseModel } from '../BaseModel';

export type BookingReasonData = {
  uuid: string;
  name: string;
  key: string;
  category: string;
};

export class BookingReason extends BaseModel<BookingReasonData> {
  get name(): string {
    return this.data.name;
  }

  get uuid(): string {
    return this.data.uuid;
  }
}
