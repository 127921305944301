














































import Component from 'vue-class-component';
import Vue from 'vue';
import { User } from '@/models/User';

@Component
export default class HeaderUserProfile extends Vue {
  menu = [
    // {
    //   icon: 'mdi-account-box-outline',
    //   key: 'menu.profile',
    //   text: 'Profile',
    //   link: '/users/edit',
    // },
    // {
    //   icon: 'mdi-email-outline',
    //   key: 'menu.email',
    //   text: 'Email',
    //   link: '/apps/email',
    // },
    // {
    //   icon: 'mdi-format-list-checkbox',
    //   key: 'menu.todo',
    //   text: 'Todo',
    //   link: '/apps/todo',
    // },
    // {
    //   icon: 'mdi-email-outline',
    //   key: 'menu.chat',
    //   text: 'Chat',
    //   link: '/apps/chat',
    // },
    // {
    //   icon: 'mdi-email-outline',
    //   key: 'menu.board',
    //   text: 'Board',
    //   link: '/apps/board',
    // },
  ];

  async logout(): Promise<void> {
    await User.logout();
    this.$router.push({ name: 'login' });
  }
}
