import { BaseModel } from '../BaseModel';

export type AddressData = {
  uuid: string;
  address_1: string;
  address_2?: string;
  address_3?: string;
  postcode: string;
  suburb: string;
  state: string;
  country: string;
  traditionalPlaceName?: string;
  lng?: string | number;
  lat?: string | number;
};

export class Address extends BaseModel<AddressData> {
  static get states(): string[] {
    return ['QLD', 'TAS', 'NSW', 'ACT', 'NT', 'VIC', 'WA'];
  }

  formatted(): string {
    if (!this.data.uuid) {
      return 'Address has not been created yet.';
    }
    return `${this.data.address_1}${this.data.suburb ? ' ' : ''}${this.data.suburb}, ${this.data.postcode}`;
  }

  slug(plural = true): string {
    return (this.constructor.name + (plural ? 'es' : '')).toLowerCase();
  }
}
