/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import { axios } from './axios';

declare global {
  interface Window {
    Pusher: any;
  }
}

export const initEcho = (): any => {
  window.Pusher = Pusher;
  return new Echo({
    broadcaster: 'pusher',
    key: 'yYQs5DQlwD',
    wsHost: process.env.VUE_APP_WEBSOCKETS_URL,
    wsPort: 6001,
    wssHost: process.env.VUE_APP_WEBSOCKETS_URL,
    wssPort: 6001,
    forceTLS: false,
    disableStats: true,
    authEndpoint: process.env.VUE_APP_WEBSOCKETS_AUTH_URL + '/broadcasting/auth/',
    enabledTransports: ['ws', 'wss'],

    authorizer: (channel: { name: any }, options: any) => {
      return {
        authorize: (socketId: any, callback: (authorize: boolean, data: any) => void) => {
          axios
            .post(process.env.VUE_APP_WEBSOCKETS_AUTH_URL + '/broadcasting/auth/', {
              socket_id: socketId,
              channel_name: channel.name,
            })
            .then((response) => {
              callback(false, response.data);
            })
            .catch((error) => {
              callback(true, error);
            });
        },
      };
    },
  });
};

let echo: Echo;

export const useEcho = (): { echo: Echo } => {
  if (!echo) {
    echo = initEcho();
  }

  return {
    echo,
  };
};
