import { BaseModel } from '../BaseModel';

export type BookingNoteData = {
  uuid: string;
  name: string;
  notes: string;
  updatedAt: string;
  createdAt: string;
};

export class BookingNote extends BaseModel<BookingNoteData> {
  get name(): string {
    return this.data.name;
  }

  get uuid(): string {
    return this.data.uuid;
  }
}
