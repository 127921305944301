/* eslint-disable @typescript-eslint/no-unused-vars */
import { APIService, IServiceProvider } from '@/services';
import { Builder, PaginatedResponse } from './Builder';
import { IStorageProvider } from '@/store';
import { RawLocation } from 'vue-router';
import { camel, kebab } from '@/utils/string';
import { capitalizeFirstLetter } from '@/filters/capitalize';
import { cloneDeep } from 'lodash-es';
import { router } from '@/router';

export interface BaseModelData {
  uuid: string;
}

type MessageTypes = 'updated' | 'created';

export type Messages = {
  [key in MessageTypes]: string;
};

export type ModelRelations<M extends BaseModel = BaseModel> = {
  [K in keyof Partial<M>]: M[K];
};

export type AppendedAttributes<M extends BaseModel = BaseModel> = {
  [K in keyof Partial<M>]: (data?: unknown) => M[K];
};

export type ConstructorOptions = {
  relations: boolean;
};

export type Failable<M> = void | null | M;

type NonnullableHasOneConfig<D> = {
  key?: keyof D;
  loadRelations?: boolean;
  nullable: false;
};

type NullableHasOneConfig<D> = {
  key?: keyof D;
  loadRelations?: boolean;
  nullable: true;
};

type HasOneConfig<D> = NonnullableHasOneConfig<D> | NullableHasOneConfig<D>;

type HasOneReturn<Config, Data, Model> = Config extends NonnullableHasOneConfig<Data>
  ? Model
  : Config extends NullableHasOneConfig<Data>
  ? Model | null
  : never;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface MetaBaseModel<M extends BaseModel, D> {
  new (): M;
  builder<M extends BaseModel, D = NonNullable<M['data']>>(this: MetaBaseModel<M, D>): Builder<M, D>;
  findOrFailRedirect?: RawLocation;
}

export class BaseModel<ModelData extends BaseModelData = BaseModelData> {
  public asParent = true;
  parent = (): null | BaseModel => null;
  public _relations: ModelRelations = {};
  public _tempData: ModelData | null = null;

  constructor(
    public data: ModelData = {} as ModelData,
    options: ConstructorOptions = {
      relations: true,
    }
  ) {
    if (data && options.relations) {
      this.setRelations();
      this.setAppends();
    }
  }

  static storageProvider: IStorageProvider;
  static serviceProvider: IServiceProvider;
  static findOrFailRedirect?: RawLocation;

  static get $api(): APIService {
    return BaseModel.serviceProvider.api;
  }

  static setStorage(storageProvider: IStorageProvider): void {
    BaseModel.storageProvider = storageProvider;
  }

  static setServices(serviceProvider: IServiceProvider): void {
    BaseModel.serviceProvider = serviceProvider;
  }

  static builder<M extends BaseModel, D = M['data']>(this: MetaBaseModel<M, D>): Builder<M, D> {
    return new Builder(new this(), this, BaseModel.$api);
  }

  static async paginate<M extends BaseModel, D = M['data']>(
    this: MetaBaseModel<M, D>,
    pageNumber?: number,
    perPage?: number
  ): Promise<PaginatedResponse<M[]>> {
    const builder = this.builder();
    return await builder.paginate(pageNumber, perPage);
  }

  static where<M extends BaseModel, T, D = M['data']>(
    this: MetaBaseModel<M, D>,
    property: string,
    value: T,
    nullable?: boolean
  ): Builder<M, D> {
    const builder = this.builder();
    return builder.where(property, value, nullable);
  }

  static sortBy<M extends BaseModel, T, D = M['data']>(
    this: MetaBaseModel<M, D>,
    property: string,
    desc = false
  ): Builder<M, D> {
    const builder = this.builder();
    return builder.sortBy(property, desc);
  }

  static async get<M extends BaseModel, D = M['data']>(this: MetaBaseModel<M, D>): Promise<M[]> {
    const builder = this.builder();
    return await builder.get();
  }

  static async getAll<M extends BaseModel, D = M['data']>(this: MetaBaseModel<M, D>): Promise<M[]> {
    const builder = this.builder();

    const results = await builder.getAll();
    return results;
  }

  static async findOrFail<M extends BaseModel, D = M['data']>(
    this: MetaBaseModel<M, D>,
    uuid: string
  ): Promise<M | null> {
    try {
      const builder = this.builder();
      return await builder.find(uuid);
    } catch (e) {
      if (this.findOrFailRedirect) {
        router.push(this.findOrFailRedirect);
      }
      return null;
    }
  }

  static async find<M extends BaseModel, D = M['data']>(this: MetaBaseModel<M, D>, uuid: string): Promise<M> {
    const builder = this.builder();
    return await builder.find(uuid);
  }

  static with<M extends BaseModel, D = M['data']>(this: MetaBaseModel<M, D>, ...include: string[]): Builder<M, D> {
    const builder = this.builder();
    return builder.with(...include);
  }

  static async create<M extends BaseModel, D = M['data']>(this: MetaBaseModel<M, D>, data: D): Promise<M> {
    const builder = this.builder();
    return await builder.create(data);
  }

  static async update<M extends BaseModel, D = M['data']>(
    this: MetaBaseModel<M, D>,
    data: D & { uuid: string }
  ): Promise<M> {
    const builder = this.builder();
    return await builder.update(data);
  }

  clone<M extends BaseModel>(this: M): M {
    return cloneDeep(this);
  }

  get tempData(): ModelData {
    if (this._tempData == null) {
      this._tempData = { ...this.data };
    }
    return this._tempData;
  }

  set tempData(data: ModelData) {
    this._tempData = data;
  }

  get uuid(): string {
    return this.data.uuid;
  }

  builder<M extends BaseModel, D = M['data']>(this: M): Builder<M, D> {
    return new Builder(this, this.constructor as MetaBaseModel<M, D>, BaseModel.$api);
  }

  async create<M extends BaseModel, D extends BaseModelData>(this: M): Promise<M> {
    const builder = this.builder();
    if (!this._tempData) {
      throw new Error('Data must be set on the model');
    }
    const model = await builder.create(this.serialise());
    return model;
  }

  async update<M extends BaseModel, D extends Partial<ModelData>>(
    this: M,
    data: D | null = null,
    config = { message: true }
  ): Promise<M> {
    // TODO - handle both null
    const builder = this.builder();
    if (!data) {
      data = this.tempData as D;
    }
    return await builder.update(this.serialise(data), config);
  }

  async updateRaw<M extends BaseModel>(this: M, data: unknown, config = { message: true }): Promise<M> {
    // TODO - handle both null
    const builder = this.builder();
    return await builder.update(data as ModelData, config);
  }

  async delete<M extends BaseModel, D extends BaseModelData>(this: M, data: D | null = null): Promise<M> {
    const builder = this.builder();
    return await builder.delete();
  }

  async directDelete<M extends BaseModel, D extends BaseModelData>(this: M, data: D | null = null): Promise<M> {
    const builder = this.builder();
    return await builder.directDelete();
  }

  async directUpdate<M extends BaseModel, D extends Partial<ModelData>>(
    this: M,
    data: unknown,
    config = { message: true }
  ): Promise<M> {
    const builder = this.builder();
    return await builder.directUpdate(data as ModelData, config);
  }

  async refresh<M extends BaseModel>(this: M): Promise<M> {
    // TODO - handle both null
    const builder = this.builder();
    return Object.assign(this, await builder.find(this.data.uuid));
  }

  setAppends(): void {
    for (const [key, value] of Object.entries(this.appends())) {
      const data = this.data[key as keyof this['data']];

      if (value) {
        const append = value(data);

        Object.assign(this, {
          [key]: append,
        });
      }
    }
  }

  setRelations(): void {
    for (const [key, value] of Object.entries(this.relations())) {
      Object.defineProperty(this, key, {
        enumerable: true,
        get(this: BaseModel) {
          return this._relations[key as keyof Partial<typeof this>];
        },
        set(this: BaseModel, val) {
          this._relations[key as keyof Omit<Partial<typeof this>, 'messages' | 'uuid'>] = val;

          const serialisedVal = Array.isArray(val) ? val.forEach((item) => item?.serialise()) : val?.serialise();

          Object.assign(this, {
            data: {
              ...this.data,
              [key]: serialisedVal,
            },
            tempData: {
              ...this.tempData,
              [key]: serialisedVal,
            },
          });
        },
      });
      Object.assign(this, {
        [key]: value,
      });
    }
  }

  slug(plural = true): string {
    return kebab(this.constructor.name + (plural ? 's' : '')).toLowerCase();
  }

  singularSlug(): string {
    return this.slug(false);
  }

  pluralSlug(): string {
    let slug = this.slug();
    const parent = this.parent();
    if (parent) {
      slug = parent.resourceSlug() + slug;
    }
    return slug;
  }

  directSlug(): string {
    return `${this.slug()}${this.data.uuid ? '/' + this.data.uuid + '/' : ''}`;
  }

  resourceSlug(): string {
    let slug = `${this.slug()}${this.data.uuid ? '/' + this.data.uuid + '/' : ''}`;

    const parent = this.parent();
    if (parent) {
      slug = parent.resourceSlug() + slug;
    }
    return slug;
  }

  exists(): boolean {
    return !!this.data && !!this.data.uuid;
  }

  relations(): ModelRelations {
    return {};
  }

  appends(): AppendedAttributes {
    return {};
  }

  hasLazy<M extends BaseModel, D = M['data']>(
    type: new (data: D) => M,
    config: {
      nested: boolean;
      key?: string;
    } = {
      nested: true,
    }
  ): Builder<M> {
    const relation = new type({} as D);
    const key = config.key;
    if (key) {
      relation.slug = () => key;
    }

    if (config.nested) {
      relation.setParent(this);
    } else {
      this.setParent(null);
      relation.setParent(this);
    }

    return relation.builder();
  }

  hasMany<M extends BaseModel, D = M['data']>(
    type: new (data: D, options?: ConstructorOptions) => M,
    config?: {
      key?: keyof ModelData;
      loadRelations: boolean;
    }
  ): M[] {
    const loadRelations = config?.loadRelations ?? true;
    const slug = config?.key ?? (camel(new type({} as D, { relations: loadRelations }).slug()) as keyof ModelData);
    const data = this.data[slug] as unknown as D[];

    if (data) {
      const relations = data.map((data: D) => {
        const relation = new type(data, { relations: loadRelations });
        relation.setParent(this);
        return relation;
      });
      return relations;
    }

    return [];
  }

  hasOne<C extends HasOneConfig<ModelData>, M extends BaseModel, D = M['data']>(
    type: new (data: D, options?: ConstructorOptions) => M,
    config = {
      nullable: false,
    } as C
  ): HasOneReturn<C, ModelData, M> {
    const slug = config?.key ?? (camel(new type({} as D).slug(false)) as keyof ModelData);
    const data = this.data[slug] as unknown as D;

    if (!data) {
      return null as HasOneReturn<C, ModelData, M>;
    }
    const relation = new type(data, { relations: config?.loadRelations ?? true });

    relation.setParent(this);
    return relation as HasOneReturn<C, ModelData, M>;
  }

  belongsTo<C extends HasOneConfig<ModelData>, M extends BaseModel, D = M['data']>(
    type: new (data: D, options?: ConstructorOptions) => M,
    config = {
      nullable: false,
    } as C
  ): HasOneReturn<C, ModelData, M> {
    const slug = config?.key ?? (camel(new type({} as D).slug(false)) as keyof ModelData);
    const data = this.data[slug] as unknown as D;

    if (!data) {
      return null as HasOneReturn<C, ModelData, M>;
    }
    const relation = new type(data, { relations: config?.loadRelations ?? true });

    return relation as HasOneReturn<C, ModelData, M>;
  }

  asParentFor<M extends BaseModel>(relation: M): void {
    relation.parent = () => (this.asParent ? this : null);
  }

  setParent<M extends BaseModel>(parent: M | null): void {
    this.parent = () => (parent?.asParent ? parent : null);
  }

  get messages(): Messages {
    return {
      updated: capitalizeFirstLetter(`${this.singularSlug()} updated.`),
      created: capitalizeFirstLetter(`${this.singularSlug()} created.`),
    };
  }

  serialise(data?: Partial<ModelData>): ModelData {
    const serialised = {} as ModelData;
    for (const [key, value] of Object.entries(data ?? this.tempData)) {
      let serialisedValue = value;
      if (value?.uuid) {
        serialisedValue = value.uuid;
      } else if (Array.isArray(value)) {
        serialisedValue = value.map((item) => {
          return item.uuid ? item.uuid : item;
        });
      }
      Object.assign(serialised, {
        [key]: serialisedValue,
      });
    }

    return serialised;
  }
}
