









import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class MessageBar extends Vue {
  get show(): boolean {
    return this.$storage.status.message != null;
  }

  set show(val: boolean) {
    this.$storage.status.store.message = null;
  }

  get text(): string | null {
    return this.$storage.status.message;
  }

  get error(): boolean {
    return this.$storage.status.error;
  }
}
