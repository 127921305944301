import { BaseModel, ModelRelations } from '../BaseModel';
import { Builder } from '../Builder';
import { Client, ClientData } from '../Client';
import { Resource } from '../Resource/resource';

export type ResourceTopicData = {
  uuid: string;
  name: string;
  start: string;
  end: string;
  clients: ClientData[];
};

export class ResourceTopic extends BaseModel<ResourceTopicData> {
  clients!: Client[];
  resources(): Builder<Resource> {
    return this.hasLazy(Resource);
  }

  relations(): ModelRelations<ResourceTopic> {
    return {
      clients: this.hasMany(Client),
    };
  }
}
