import { BaseModel, ModelRelations } from '../BaseModel';
import { Client, ClientData } from '../Client';
import { Department, DepartmentData } from '../Department';
import { Position } from '../Position';
import { Shift } from '../Shift';
import { Site, SiteData } from '../Site';

export interface PatientLocationData {
  uuid: string;
  client: ClientData;
  site: SiteData;
  department: DepartmentData;
}

export class PatientLocation extends BaseModel<PatientLocationData> {
  public asParent = false;

  public site!: Site;
  public client!: Client;
  public department!: Department;
  public position!: Position | null;
  public shift!: Shift | null;

  relations(): ModelRelations<PatientLocation> {
    return {
      site: this.hasOne(Site, { nullable: false }),
      client: this.hasOne(Client, { nullable: false }),
      department: this.hasOne(Department, { nullable: false }),
      position: this.hasOne(Position, { nullable: true }),
      shift: this.hasOne(Shift, { nullable: true }),
    };
  }
}
