import { ProviderLayoutDefault } from '../../../layouts';
import { RouteConfig } from '@/types/router';
import { makeRouteNames } from '@/router/helpers';

const routeNames = makeRouteNames({
  clients: {
    all: 'clients.all',
    view: 'clients.view',
    practitioners: 'clients.practitioners',
    emails: 'clients.emails',
    messages: 'clients.messages',
    supervisors: 'clients.supervisors',
  },
});

const routes: Array<RouteConfig> = [
  {
    path: '/clients',
    component: () => import('../views/ClientsModulePage.vue'),
    meta: {
      layout: ProviderLayoutDefault,
    },
    children: [
      {
        path: '',
        name: routeNames.clients.all,
        component: () => import('../views/ClientsIndexPage.vue'),
        meta: {
          title: 'Clients',
          label: 'All Clients',
        },
      },
      {
        path: ':uuid',
        component: () => import('../views/ClientsViewPage/ClientsViewPage.vue'),
        children: [
          {
            path: '',
            name: routeNames.clients.view,
            component: () => import('../views/ClientsViewPage/ClientsLocationsPage.vue'),
          },
          {
            path: 'practitioners',
            name: routeNames.clients.practitioners,
            component: () => import('../views/ClientsViewPage/ClientPractitionersPage.vue'),
          },
          {
            path: 'supervisors',
            name: routeNames.clients.supervisors,
            component: () => import('../views/ClientsViewPage/ClientSupervisorsPage.vue'),
          },
          {
            path: 'emails',
            name: routeNames.clients.emails,
            component: () => import('../views/ClientsViewPage/ClientEmailsPage.vue'),
          },
          {
            path: 'messages',
            name: routeNames.clients.messages,
            component: () => import('../views/ClientsViewPage/ClientMessagesPage.vue'),
          },
        ],
      },
    ],
  },
];

export const clientRoutes = { routeNames, routes };
