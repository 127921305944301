











import Component from 'vue-class-component';
import NavMenu from './NavMenu.vue';
import Vue from 'vue';
import { MenuItem, menuItems } from './mainMenuItems';

@Component({
  components: {
    NavMenu,
  },
})
export default class MainMenu extends Vue {
  get menu(): MenuItem[] {
    return menuItems();
  }
}
