import { ProviderLayoutDefault } from '../../../layouts';
import { RouteConfig } from '@/types/router';
import { makeRouteNames } from '@/router/helpers';

const routeNames = makeRouteNames({
  reporting: {
    client: 'reporting.client',
  },
});

const routes: Array<RouteConfig> = [
  {
    path: '/reports',
    component: () => import('../views/ReportingModulePage.vue'),
    meta: {
      layout: ProviderLayoutDefault,
    },
    children: [
      {
        path: 'client',
        name: routeNames.reporting.client,
        component: () => import('../views/ClientReportPage.vue'),
        meta: {
          title: 'Clients',
        },
      },
    ],
  },
];

export const reportingRoutes = { routeNames, routes };
