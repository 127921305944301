import { ProviderLayoutDefault } from '../../../layouts';
import { RouteConfig } from '@/types/router';
import { makeRouteNames } from '@/router/helpers';

const routeNames = makeRouteNames({
  practitioners: {
    all: 'practitioners.all',
    view: 'practitioners.view',
    create: 'practitioners.create',
  },
});

const routes: Array<RouteConfig> = [
  {
    path: '/practitioners',
    component: () => import('../views/PractitionersModulePage.vue'),
    meta: {
      layout: ProviderLayoutDefault,
    },
    children: [
      {
        path: '',
        name: routeNames.practitioners.all,
        component: () => import('../views/PractitionersIndexPage.vue'),
        meta: {
          title: 'Practitioners',
        },
      },
      {
        path: 'create',
        name: routeNames.practitioners.create,
        component: () => import('../views/PractitionersCreatePage.vue'),
        meta: {
          title: 'Create',
        },
      },
      {
        path: ':uuid',
        component: () => import('../views/PractitionersViewPage/PractitionersViewPage.vue'),
        children: [
          {
            path: '',
            name: routeNames.practitioners.view,
            component: () => import('../views/PractitionersViewPage/PractitionerProfile.vue'),
            meta: {
              title: 'Practitioner',
            },
          },
        ],
      },
    ],
  },
];

export const practitionerRoutes = { routeNames, routes };
