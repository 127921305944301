import { BaseModel } from '../BaseModel';
import { Builder } from '../Builder';
import { Position } from '../Position';
import { Shift } from '../Shift';

export type DepartmentData = {
  uuid: string;
  name: string;
};

export class Department extends BaseModel<DepartmentData> {
  shifts(): Builder<Shift> {
    return this.hasLazy(Shift, { nested: false });
  }

  positions(): Builder<Position> {
    return this.hasLazy(Position, { nested: false });
  }

  get name(): string {
    return this.data?.name;
  }
}
