import { RouteConfig } from '@/types/router';
import { makeRouteNames } from '@/router/helpers';

import { ProviderLayoutDefault } from '../../../layouts';

const routeNames = makeRouteNames({
  dashboard: 'dashboard',
});

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../../bookings/views/BookingListPage/BookingListPage.vue'),
    meta: {
      layout: ProviderLayoutDefault,
      title: 'Dashboard',
    },
  },
];

export const dashboardRoutes = { routeNames, routes };
