import { format, getTime, isDate, isToday, isValid, parse, parseISO } from 'date-fns';

export const makeDate = (value: Date | string): Date => {
  return value instanceof Date ? value : new Date(value);
};

export const formatDate = (value: Date | string | null, filterFormat = 'dd/MM/yyyy'): string | null => {
  if (!value) {
    return null;
  }
  let date: Date;
  if (!isDate(value)) {
    date = parseISO(value as string);
  } else {
    date = value as Date;
  }
  return format(date, filterFormat);
};

export const formatDateForInteraction = (value: Date | string): string | null => {
  if (!value) {
    return null;
  }
  const date = makeDate(value);
  if (isToday(date)) {
    return formatDate(date, 'HH:mm aaa');
  } else {
    return formatDate(date, 'dd/MM');
  }
};

export const formatDateTime = (value: Date | string | null, filterFormat = 'dd/MM/yyyy hh:mmaaa'): string | null => {
  return formatDate(value, filterFormat);
};

export const formatTime = (value: string | null, filterFormat = 'HH:mm aaa'): string | null => {
  if (!value) {
    return null;
  }

  const [hours, minutes] = value.split(':');

  const date = new Date();
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));

  return format(date, filterFormat);
};

export const dateFromDateTimeString = (
  dateString: string | null,
  time: string | null = '00:00',
  format = 'yyyy-MM-dd HH:mm'
): Date | null => {
  if (!dateString) {
    return null;
  }

  const date = parse(dateString + ' ' + time, format, new Date());

  if (isValid(date)) {
    return date;
  }

  return null;
};

export const hoursFromTime = (time: string | null): string | null => {
  if (!time) {
    return null;
  }

  const { hours } = timeElements(time);

  return hours;
};

export const minutesFromTime = (time: string | null): string | null => {
  if (!time) {
    return null;
  }
  const { minutes } = timeElements(time);

  return minutes;
};

export const ampmFromTime = (time: string | null): string | null => {
  if (!time) {
    return null;
  }

  return formatTime(time, 'aaa');
};

export const timeElements = (time: string): { hours: string; minutes: string } => {
  const [hours, minutes] = time.split(':');

  return {
    hours,
    minutes,
  };
};

export function roundMinutes(x: number | string, interval = 15): number {
  let rounded: number = parseInt(x as string) % 60;
  if (rounded < interval) {
    return 0;
  }
  rounded = Math.floor(rounded / interval) * interval;
  return rounded;
}

export const roundTimeMinutes = (time: Date | null | string, interval: number): string | null => {
  if (!time) {
    return null;
  }

  const { hours, minutes } = timeElements(time as string);

  if (!minutes) {
    return null;
  }

  const roundedMinutes = roundMinutes(minutes, interval);

  return `${hours}:${roundedMinutes}`;
};

export const getTimeFromISO = (isoDateString: string): number => {
  return getTime(parseISO(isoDateString));
};

export const getTimesFromISO = (...isoStrings: string[]): number[] => {
  const times = isoStrings.map((time: string) => getTimeFromISO(time));

  return times;
};
