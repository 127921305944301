import { RouteConfig } from '@/types/router';
import { makeRouteNames } from '@/router/helpers';

import PatientLayout from '@/domains/patient/layouts/PatientLayout/PatientLayout.vue';

const routeNames = makeRouteNames({
  resources: 'patient.resources',
  viewResourceTopic: 'patient.resources.view',
  viewResource: 'patient.resource.view',
  myDetails: 'patient.details',
  goToResource: 'goToResource',
  goToResourceTopic: 'goToResourceTopic',
});

const routes: Array<RouteConfig> = [
  {
    path: '/user/resource-topics',
    name: routeNames.resources,
    component: () => import('../views/ResourceTopicsPage.vue'),
    meta: {
      layout: PatientLayout,
      title: 'Dashboard',
    },
  },
  {
    path: '/user/resource-topics/:uuid',
    name: routeNames.viewResourceTopic,
    component: () => import('../views/ViewResourceTopicPage.vue'),
    props: true,
    meta: {
      requiresAuth: false,
      layout: PatientLayout,
      title: 'Dashboard',
    },
  },
  {
    path: '/user/resources/:uuid',
    name: routeNames.viewResource,
    component: () => import('../views/ViewResourcePage.vue'),
    props: true,
    meta: {
      requiresAuth: false,
      layout: PatientLayout,
      title: 'Dashboard',
    },
  },
  {
    path: '/user/my-details',
    name: routeNames.myDetails,
    component: () => import('../views/MyDetailsPage.vue'),
    meta: {
      layout: PatientLayout,
      title: 'Dashboard',
    },
  },
  {
    path: '/go-to-resource',
    name: routeNames.goToResource,
    meta: {
      layout: PatientLayout,
      title: 'Dashboard',
    },
    redirect: (to) => {
      return { name: routeNames.viewResource, params: { uuid: to.query.resource as string } };
    },
  },
  {
    path: '/go-to-resource-topic',
    name: routeNames.goToResourceTopic,
    meta: {
      layout: PatientLayout,
      title: 'Dashboard',
    },
    redirect: (to) => {
      return { name: routeNames.viewResourceTopic, params: { uuid: to.query.topic as string } };
    },
  },
];

export const dashboardRoutes = { routeNames, routes };
