import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';

// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css';
// Vuetify's CSS styles
// import 'vuetify/dist/vuetify.min.css';

import '@/styles/overrides.scss';

Vue.use(Vuetify);

export const colours = {
  // background: '#ffffff',
  surface: '#f2f5f8',
  primary: '#2abf64',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  cancel: '#EEEEEE',
};

const vuetify = new Vuetify({
  theme: {
    // dark: config.theme.globalTheme === 'dark',
    options: {
      customProperties: true,
    },
    themes: {
      // dark: config.theme.dark,
      light: colours,
    },
  },
});

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
});

export default vuetify;
