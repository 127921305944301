import './plugins/axios';
import './plugins/composition';
import App from './App.vue';
import Vue, { VNode, VueConstructor } from 'vue';
import vuetify from './plugins/vuetify';
import { BaseModel } from './models/BaseModel';
import { createApp } from './plugins/composition';
// import { RayPlugin } from 'vue-ray/vue2';
import { prepareStorage } from './plugins/storage';
import { registerServices } from './plugins/services';
import { router } from './router';

/**
 * Import style overrides.
 * Variables are picked up by vuetify-loader automatically
 */

import './styles/utilities.scss';

// import './styles/variables.scss';

// Filters
import './filters/case';
import './filters/formatDate';
// import { bugsnagVue } from './plugins/bugsnag';

export const services = registerServices();
export const storage = prepareStorage(services);

BaseModel.setStorage(storage);
BaseModel.setServices(services);

Vue.config.productionTip = false;

const app = createApp({
  router,
  vuetify,
  render: (h: (a: VueConstructor<Vue>) => VNode) => h(App as VueConstructor<Vue>),
});

// app.use(bugsnagVue);

// app.use(VueApexCharts);

app.mount('#app');

// new Vue({

// }).$mount('#app');

// Vue.use(RayPlugin, { interceptErrors: true, host: '127.0.0.1', port: 23517 });
