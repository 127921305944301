import Vue from 'vue';
import { IServiceProvider } from '@/services';
import { IStorageProvider, storageProvider } from '../store';

export const prepareStorage = (services: IServiceProvider): IStorageProvider => {
  const storage = storageProvider(services);

  Vue.mixin({
    beforeCreate() {
      this.$storage = storage;
    },
  });

  return storage;
};
