import { Attachment, GroupInteraction, ResourceTopic } from '..';
import { BaseModel, ModelRelations } from '../BaseModel';
import { Builder } from '../Builder';
import { Patient } from '../Patient';
import { Site } from '../Site';
import { routeNames } from '@/router';

export type ClientData = {
  uuid: string;
  name: string;
};

export class Client extends BaseModel<ClientData> {
  eagerSites!: Site[] | null;

  static findOrFailRedirect = { name: routeNames.practitioner.clients.all };

  get hasNoSites(): boolean {
    const sites = this.eagerSites ?? [];

    return sites.length === 0;
  }

  resourceTopics(): Builder<ResourceTopic> {
    return this.hasLazy(ResourceTopic);
  }

  sites(): Builder<Site> {
    return this.hasLazy(Site);
  }

  patients(): Builder<Patient> {
    return this.hasLazy(Patient);
  }

  groupInteractions(): Builder<GroupInteraction> {
    return this.hasLazy(GroupInteraction);
  }

  attachments(): Builder<Attachment> {
    return this.hasLazy(Attachment, { nested: false });
  }

  async report(start: string, end: string, sites: Site[] = []): Promise<any> {
    return await BaseModel.$api.post(`clients/${this.uuid}/reports/monthly`, {
      start,
      end,
      sites: sites.map((site: Site) => site.uuid),
      indicators: [
        'systolic-blood-pressure',
        'diastolic-blood-pressure',
        'cholesterol',
        'bmi',
        'waist-hip-ratio',
        'waist',
        'weight',
        'vegetables-per-day',
        'fruit-per-day',
        'litres-water-per-day',
        'exercise-per-week',
        'hours-sleep-per-night',
        'bgl-2-hours-within-eating',
      ],
    });
  }

  get name(): string {
    return this.data?.name;
  }

  relations(): ModelRelations<Client> {
    return {
      eagerSites: this.hasMany(Site, { loadRelations: false }),
    };
  }
}
