import { BaseModel } from '../BaseModel';

export type Validation = {
  key: string;
  label: string;
  params: string | null;
};

export type Params = {
  multiple?: boolean;
};

export type QuestionData = {
  uuid: string;
  key: string;
  name: string;
  type: string;
  units: string;
  options: string[];
  validations: Validation[];
  answer: string | number | null;
  calculated: boolean;
  params: Params;
};

export class Question extends BaseModel<QuestionData> {
  get component(): string {
    const componentMap = {
      text: 'v-text-field',
      textarea: 'v-textarea',
    };

    return componentMap[this.data.type as keyof typeof componentMap];
  }
}
