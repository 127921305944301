import { ProviderLayoutDefault } from '@/domains/practitioner/layouts';
import { RouteConfig } from 'vue-router';
import { makeRouteNames } from '@/router/helpers';

const routeNames = makeRouteNames({
  assessments: {
    index: 'assessments',
  },
});

const routes: Array<RouteConfig> = [
  {
    path: '/assessments',
    component: () => import('../views/AssessmentsIndexPage.vue'),
    meta: {
      layout: ProviderLayoutDefault,
    },
  },
];

export const assessmentRoutes = { routeNames, routes };
