import { BaseModel, ModelRelations } from '../BaseModel';
import { Patient, PatientData, User, UserData } from '..';
import { differenceInMinutes, parseISO } from 'date-fns';

export type InteractiondData = {
  uuid: string;
  excerpt: string;
  recipient: UserData;
  sender: UserData;
  sentAt: string;
  sendAt: string;
  status: keyof typeof statusColours;
  content: string;
  senderType: string;
  patient: PatientData;
  subject: string;
  readStatus: string;
  inbound: boolean;
};

const statusColours = {
  scheduled: 'info',
  queued: 'warning ',
  sent: 'success',
  delivered: 'success',
  received: 'success',
};

export class Interaction extends BaseModel<InteractiondData> {
  public patient!: Patient | null;

  get isUnread(): boolean {
    return this.data.readStatus === 'unread';
  }

  get timeSinceSent(): string {
    const sentAt = parseISO(this.data.sentAt);

    const minutes = differenceInMinutes(new Date(), sentAt);

    if (minutes < 60) {
      return minutes + ' mins';
    }

    if (minutes < 60 * 24) {
      return Math.round(minutes / 60) + ' hours';
    }

    return Math.round(minutes / (60 * 24)) + ' days';
  }

  get excerpt(): string {
    return this.data.content?.substring(0, 100) + '...';
  }

  isFrom(user: User): boolean {
    return this.data.sender.uuid == user.uuid;
  }

  async markAsRead(): Promise<void> {
    await this.directUpdate({ readStatus: 'read' });
    this.refresh();
  }

  getStatusColour(): string {
    return statusColours[this.data.status];
  }

  relations(): ModelRelations<Interaction> {
    return {
      patient: this.hasOne(Patient),
    };
  }
}
