







































import { Interaction } from '@/models';
import { computed, defineComponent, onMounted, ref } from 'vue-demi';

import { storage } from '@/main';
import { useEcho } from '@/plugins/echo';
import { useLoader } from '@/composables/useLoader';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'HeaderNotifications',
  setup() {
    const interactions = ref<Interaction[]>([]);
    const total = ref(0);
    const loadMore = ref(0);
    const perPage = 25;
    const bar = ref(false);

    const { echo } = useEcho();

    const practitioner = computed(() => storage.auth.asPractitioner);

    echo
      .private(`interactions.${practitioner.value.uuid}`)
      .listen('.interaction.inbound', async () => {
        await loadInteractions();
        toggleBar();
      })
      .listen('.interaction.marked-as-read', async () => {
        await loadInteractions();
      });

    const { loading, withLoader } = useLoader();

    const { router } = useRouter();

    const toggleBar = () => {
      bar.value = true;
    };

    const loadInteractions = async () =>
      withLoader(async () => {
        const { data, meta } = await practitioner.value
          .interactions()
          .where('interactable_type', 'sms')
          .where('read_status', 'unread')
          .where('inbound', true)
          .append('content')
          .sortBy('sent_at')
          .paginate(1, loadMore.value * perPage);
        total.value = meta.total;
        interactions.value = data;
      });

    onMounted(() => loadInteractions());

    const goToPatient = (interaction: Interaction) => {
      // interaction.markAsRead();
      router.push({ name: 'patients.view.profile.sms', params: { uuid: interaction.data.patient.uuid } });
    };

    return { interactions, total, goToPatient, bar };
  },
});
