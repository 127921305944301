import { IServiceProvider } from '@/services';
import { reactive } from '@/plugins/composition';

export interface IStatusState {
  message: string | null;
  error: boolean;
}

const initialState: IStatusState = {
  message: null,
  error: false,
};

export class StatusStorage {
  constructor(public $services: IServiceProvider, public store: IStatusState = reactive({ ...initialState })) {}

  setMessage(message: string): void {
    this.store.message = message;
    setTimeout(() => {
      this.clearMessage();
      this.store.error = false;
    }, 2000);
  }

  setErrorMessage(message: string): void {
    this.store.message = message;
    this.store.error = true;

    setTimeout(() => {
      this.clearMessage();
      this.store.error = false;
    }, 5000);
  }

  get message(): string | null {
    return this.store.message;
  }

  get error(): boolean {
    return this.store.error;
  }

  clearMessage(): void {
    this.store.message = null;
  }
}
