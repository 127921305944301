import { BaseModel } from '../BaseModel';
import { Builder } from '../Builder';
import { Question } from '../Question';

export type SurveyData = {
  uuid: string;
  name: string;
};

export class Survey extends BaseModel<SurveyData> {
  questions(): Builder<Question> {
    return this.hasLazy(Question, { nested: false });
  }
}
