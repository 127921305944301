import Vue from 'vue';
import { IServiceProvider, serviceProvider } from '../services';

export const registerServices = (): IServiceProvider => {
  const services = serviceProvider();

  Vue.mixin({
    beforeCreate() {
      this.$services = services;
    },
  });

  return services;
};
