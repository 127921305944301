import { assessmentRoutes } from '../modules/assessments/router';
import { bookingRoutes } from '../modules/bookings/router';
import { clientRoutes } from '../modules/clients/router/clientRoutes';
import { dashboardRoutes } from '../modules/dashboard/router';
import { patientRoutes } from '../modules/patients/router';
import { practitionerRoutes as practitionerRoutesBase } from '../modules/practitioners/router';
import { reportingRoutes } from '../modules/reporting/router';
import { resourceRoutes } from '../modules/resources/router/resourceRoutes';

export const practitionerRouteNames = {
  ...patientRoutes.routeNames,
  ...dashboardRoutes.routeNames,
  ...clientRoutes.routeNames,
  ...practitionerRoutesBase.routeNames,
  ...bookingRoutes.routeNames,
  ...assessmentRoutes.routeNames,
  ...reportingRoutes.routeNames,
  ...resourceRoutes.routeNames,
};

export const practitionerRoutes = [
  ...patientRoutes.routes,
  ...dashboardRoutes.routes,
  ...clientRoutes.routes,
  ...practitionerRoutesBase.routes,
  ...bookingRoutes.routes,
  ...assessmentRoutes.routes,
  ...reportingRoutes.routes,
  ...resourceRoutes.routes,
];
