import { Address } from '../Address';
import { Assessment, Attachment, Booking, Interaction, Practitioner, PractitionerData } from '..';
import { Builder } from '../Builder';
import { Client } from '../Client';
import { Department } from '../Department';
import { ModelRelations } from '../BaseModel';
import { PatientLocation, PatientLocationData } from '../PatientLocation';
import { SeverityScale } from '../SeverityScale';
import { Site } from '../Site';
import { User, UserData } from '../User';

export interface PatientData extends UserData {
  lastSeen: string | null;
  location: PatientLocationData | null;
  primaryPractitioner: PractitionerData;
}

export class Patient extends User<PatientData> {
  public location!: PatientLocation;
  public primaryPractitioner!: Practitioner | null;

  bookings(): Builder<Booking> {
    return this.hasLazy(Booking, { nested: false });
  }

  locations(): Builder<PatientLocation> {
    return this.hasLazy(PatientLocation);
  }

  smsInteractions(): Builder<Interaction> {
    return this.hasLazy(Interaction, { nested: false, key: 'sms' });
  }

  emailInteractions(): Builder<Interaction> {
    return this.hasLazy(Interaction, { nested: false, key: 'emails' });
  }

  attachments(): Builder<Attachment> {
    return this.hasLazy(Attachment, { nested: false });
  }

  assessments(): Builder<Assessment> {
    return this.hasLazy(Assessment);
  }

  severityScales(): Builder<SeverityScale> {
    return this.hasLazy(SeverityScale);
  }

  get client(): Client | null {
    return this.location?.client ?? null;
  }

  get site(): Site | null {
    return this.location?.site ?? null;
  }

  get department(): Department | null {
    return this.location?.department ?? null;
  }

  relations(): ModelRelations<Patient> {
    return {
      address: this.hasOne(Address),
      location: this.hasOne(PatientLocation, { key: 'location', nullable: false }),
      primaryPractitioner: this.belongsTo(Practitioner, { key: 'primaryPractitioner', nullable: true }),
    };
  }
}
