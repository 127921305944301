import hexRgb from 'hex-rgb';
import vuetify from '@/plugins/vuetify';
import { BaseModel, ModelRelations } from '../BaseModel';
import { BookingNote, BookingNoteData } from '../BookingNote';
import { BookingReason, BookingReasonData, Patient, PatientData, Practitioner, PractitionerData, Site } from '..';
import { Builder } from '../Builder';
import { formatDate, formatDateTime } from '@/utils';
import { formatISO } from 'date-fns';
import { storage } from '@/main';

export type BookingData = {
  uuid: string;
  status: 'booked' | 'completed' | 'cancelled' | 'in-progress' | 'requested';
  startTime: string | number;
  endTime: string | number;
  patient: PatientData;
  practitioner: PractitionerData;
  duration: string;
  createdAt: string;
  updatedAt: string;
  bookingReasons: BookingReasonData[];
  notes: BookingNoteData[];
  careType: 'proactive' | 'reactive' | null;
  bookingDeliveryType: 'in person' | 'phone' | null;
};

export type BookingStatuses = BookingData['status'];

const statusColours = {
  booked: 'info',
  cancelled: 'error',
  completed: 'success',
  'in-progress': 'warning',
  requested: 'info',
};

export class Booking extends BaseModel<BookingData> implements BookingInterface {
  public practitioner!: Practitioner;
  public patient!: Patient | null;
  public site!: Site | null;
  public bookingReasons!: BookingReason[];

  static getAllowedStatuses = async (): Promise<BookingStatuses[]> => {
    return await BaseModel.$api.get('/booking-statuses');
  };

  static async createQuickNote(patient: Patient): Promise<Booking> {
    const booking = new Booking();
    booking.startTime = formatISO(new Date());
    booking.endTime = formatISO(new Date());

    booking.practitioner = (await storage.auth.practitioner()) as Practitioner;
    return await patient.bookings().create(booking.serialise());
  }

  notes(): Builder<BookingNote> {
    return this.hasLazy(BookingNote, { nested: false });
  }

  colour(isDragged = false): string {
    const primary = vuetify.userPreset.theme?.themes?.light?.primary as string;
    const colour = hexRgb(primary, { format: 'css', alpha: isDragged ? 0.7 : 1 });
    return colour;
  }

  get createdAt(): string {
    return formatDateTime(this.data.createdAt) as string;
  }

  get updatedAt(): string {
    return formatDateTime(this.data.updatedAt) as string;
  }

  get calendarLabel(): string {
    if (this.patient) {
      return this.patient.name;
    }

    if (this.site) {
      return this.site.name + ' - ' + this.bookingReasons[0]?.name;
    }

    return 'Booking';
  }

  get startTime(): string | null | number {
    return formatDate(this.data.startTime as string, 'yyyy-MM-dd HH:mm');
  }

  set startTime(value: string | null | number) {
    this.data.startTime = formatISO(new Date(value as string));
    this.tempData.startTime = formatISO(new Date(value as string));
  }

  get endTime(): string | null | number {
    return formatDate(this.data.endTime as string, 'yyyy-MM-dd HH:mm');
  }

  set endTime(value: string | null | number) {
    this.data.endTime = formatISO(new Date(value as string));
    this.tempData.endTime = formatISO(new Date(value as string));
  }

  get calStartTime(): string | null | number {
    return this.startTime;
  }

  get calEndTime(): string | null | number {
    return this.endTime;
  }

  async updateBookingReasons(): Promise<void> {
    this.update({
      bookingReasons: this.bookingReasons.map((reason: BookingReason) => reason.serialise()),
    });
  }

  getStatusColour(): string {
    return statusColours[this.data.status];
  }

  relations(): ModelRelations<Booking> {
    return {
      practitioner: this.hasOne(Practitioner, { nullable: false }),
      patient: this.hasOne(Patient),
      site: this.hasOne(Site),
      bookingReasons: this.hasMany(BookingReason),
    };
  }
}

export interface BookingInterface {
  practitioner: Practitioner;
  startTime: string | null | number;
  colour: (isDragged: boolean) => string;
  calendarLabel: string;
  createdAt: string;
  updatedAt: string;
}
