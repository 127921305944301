import { routeNames } from '@/router';

export type MenuItem = {
  items?: MenuItem[];
  text?: string;
  icon?: string;
  route?: {
    name: string;
  };
};

export const menuItems = (): MenuItem[] => [
  {
    items: [
      {
        icon: 'mdi-account',
        text: 'My Details',
        route: { name: routeNames.patient.myDetails },
      },
    ],
  },
  {
    items: [
      {
        icon: 'mdi-video',
        text: 'Resources',
        route: { name: routeNames.patient.resources },
      },
    ],
  },
  // {
  //   text: 'People',
  //   items: [
  //     {
  //       icon: 'mdi-account-multiple',
  //       text: 'Patients',
  //       route: { name: routeNames.practitioner.patients.all },
  //     },
  //     {
  //       icon: 'mdi-domain',
  //       text: 'Clients',
  //       route: { name: routeNames.practitioner.clients.all },
  //     },
  //     {
  //       icon: 'mdi-stethoscope',
  //       text: 'Practitioners',
  //       route: { name: routeNames.practitioner.practitioners.all },
  //     },
  //   ],
  // },
  // {
  //   text: 'Booking Management',
  //   items: [
  //     // {
  //     //   icon: 'mdi-calendar-today',
  //     //   text: 'Upcoming',
  //     // },
  //     {
  //       icon: 'mdi-calendar-blank-multiple',
  //       text: 'My Schedule',
  //       route: { name: routeNames.practitioner.bookings.my.day },
  //     },
  //     {
  //       icon: 'mdi-view-list',
  //       text: 'Bookings List',
  //       route: { name: routeNames.practitioner.bookings.my.list },
  //     },
  //     // {
  //     //   icon: 'mdi-calendar',
  //     //   text: 'Bookings',
  //     // },
  //   ],
  // },
  // // {
  // //   text: 'Business',
  // //   items: [
  // //     {
  // //       icon: 'mdi-bell',
  // //       text: 'Notifications',
  // //     },
  // //   ],
  // // },

  // {
  //   text: 'Reporting',
  //   items: [
  //     {
  //       text: 'Reports',
  //       icon: 'mdi-file-chart',
  //       items: [
  //         {
  //           text: 'Client Report',
  //           route: { name: routeNames.practitioner.reporting.client },
  //         },
  //       ],
  //     },
  //   ],
  // },
  // // {
  // //   text: 'Applications',
  // //   items: [
  // //     {
  // //       icon: 'mdi-sleep-off',
  // //       text: 'Fatigue',
  // //     },
  // //   ],
  // // },
  // // {
  // //   text: 'Settings',
  // //   items: [
  // //     {
  // //       icon: 'mdi-cogs',
  // //       text: 'General',
  // //     },
  // //     {
  // //       icon: 'mdi-link-variant',
  // //       text: 'Integrations',
  // //     },
  // //     {
  // //       icon: 'mdi-palette-advanced',
  // //       text: 'Personalisation',
  // //     },
  // //   ],
  // // },
];
